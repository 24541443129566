import React from 'react';
import { Helmet } from 'react-helmet-async';
import { SEOProps } from './SEO.types';

const SEO: React.FC<SEOProps> = ({ title, description }) => {
    return (
        <Helmet title={title}
            htmlAttributes={{ lang: "en" }}
            meta={[
                {
                    name: `description`,
                    content: description,
                }
            ]}
        />
    )
};

export default SEO;