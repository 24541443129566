import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "./routesConfig";
import ProtectedRoute from "./ProtectedRoute";
import { useAuthStore } from "../store/authStore";
import { useSelector } from "react-redux";
import { RootState } from "../redux-store/types";

interface AppRoutesProps {}

const AppRoutes: React.FC<AppRoutesProps> = () => {
  const userInfo = useSelector((state: RootState) => state.userInfo);
  console.log("userInfo", userInfo);
  const isAuthenticated = userInfo?.authToken ? true : false;

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            route.isProtected ? (
              <ProtectedRoute>
                <route.component />
              </ProtectedRoute>
            ) : route.path === "/signin" && isAuthenticated ? (
              <Navigate to="/home" replace />
            ) : (
              <route.component />
            )
          }
        />
      ))}
    </Routes>
  );
};

export default AppRoutes;
