import { request } from "./ApiCentral";

export const loginUser = (data) =>{
    console.log(data, " login user")
    return request(
        '/auth/login-user',
        {
          method: 'POST',
          data,
        },
        false,
        false,
      );
}

