import React from "react";
import "./Landing.css"; // Your CSS file for styling
import { SEO } from "../../components/SEO";

// Register the ScrollTrigger plugin

const partners = [
  {
    name: "Partner 1",
    image: "",
  },
  {
    name: "Partner 2",
    image: "",
  },
  {
    name: "Partner 3",
    image: "",
  },
  {
    name: "Partner 4",
    image: "",
  },
  {
    name: "Partner 5",
    image: "",
  },
];

export const Landing: React.FC = () => {
  return (
    <div>
      <SEO
        title="Enacts | Landing"
        description="Welcome to Landing page of Enacts"
      />
      <div className="landing-top-section">
        <div className="heading-container">
          <h1>Validation of the Facts</h1>
          <h4>Filling the gap in the Food System</h4>
        </div>
        <div className="image-container">
          <img src="landing/landing-img.svg" alt="landing-image" />
        </div>
      </div>
      <div className="landing-info-section">
        <div className="landing-info-container">
          <div className="shadow-div"></div>
          <div className="partners-container">
            {partners.map((data, index) => {
              return (
                <div className="partner-profile-container">
                  <div className="partner-image-container"></div>
                  <p>{data.name}</p>
                </div>
              );
            })}
          </div>
          <div className="landing-vision-section">
            <h1>Our Vision</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </p>
          </div>
          <div className="landing-bottom-section">
            <h1>
              Giga map
              <br />
              of food system
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};
