import { loginUser } from "../services/AuthServices";
import { AppDispatch } from "../index"; // Ensure this path is correct
// @ts-ignore
import { AnyAction } from "redux"; // Import AnyAction for typing

export const handleLoginUser = (
  data: { phoneNumber: string; password: string },
  callback: (args: { user: { id?: string } }) => void
): (dispatch: AppDispatch) => Promise<void> => {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: "ENABLE_LOADING" });
    try {
      const { data: response } = await loginUser(data);
      console.log("RES", response);
      callback(response);
      dispatch({ type: "SET_USER_INFO", payload: response });
      dispatch({ type: "SHOW_ERROR", payload: null });
    } catch (err) {
      console.log("NETWORK ERROR", err);
      dispatch({ type: "SHOW_ERROR", payload: err });
    } finally {
      dispatch({ type: "DISABLE_LOADING" });
    }
  };
};
