import React from "react";
import "./App.css";
import AppRoutes from "./routes/AppRoutes";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { useLocation } from "react-router-dom";

const App: React.FC = () => {
  const location = useLocation();
  const isSrollableRoute = ["/"].includes(location.pathname);

  return (
    <div
      className={isSrollableRoute ? "scrollable-layout" : "fullscreen-layout"}
    >
      <Header />
      <main className={isSrollableRoute ? "scrollable-main-content" : "main-content"}>
        <AppRoutes />
      </main>
      <Footer />
    </div>
  );
};

export default App;
