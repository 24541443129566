import { createStore, applyMiddleware } from 'redux';
import { thunk  } from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';
import { RootState } from './types';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer, applyMiddleware(thunk));

// Define AppDispatch type
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
