import { AnyAction } from 'redux';
import { UserInfo } from './types'; // Adjust the path as needed

const INIT_STATE: UserInfo = {
  userId: null,
  name: null,
  phoneNumber: null,
  email: null,
  password: null,
  authToken: null,
  userImageUri: null,
  organization: null,
  organizationName: null,
};

const userInfo = (state: UserInfo = INIT_STATE, action: AnyAction): UserInfo => {
  switch (action.type) {
    case "SET_USER_INFO":
      console.log("TALK IS CHEAP SHOW ME THE PAYLOAD", action.payload);
      return {
        ...state,
        name: action.payload.user.name,
        phoneNumber: action.payload.user.phoneNumber,
        email: action.payload.user.email,
        password: action.payload.user.password,
        userId: action.payload.user.id,
        authToken: action.payload.token,
        userImageUri: action.payload.user.userImageUri,
        organization: action.payload.user.organization,
        organizationName: action.payload.user.organizationName,
      };
    case "LOG_OUT":
      return INIT_STATE; // Resetting to initial state
    case "UPDATE_USER_INFO":
      console.log("TALK IS CHEAP SHOW ME THE UPDATED PAYLOAD", action.payload);
      return {
        ...state,
        phoneNumber: action.payload.user.phoneNumber,
        email: action.payload.user.email,
        password: action.payload.user.password,
        userId: action.payload.user.id,
        userImageUri: action.payload.user.userImageUri,
        organization: action.payload.user.organization,
        organizationName: action.payload.user.organizationName,
      };
    default:
      return state;
  }
};

export default userInfo;
