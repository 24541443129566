import React, { useEffect } from "react";
import "./Home.css";
import { SEO } from "../../components/SEO";
import { getPublicAssetPath } from "../../utils/getPublicAssets";
import { useNavigate } from "react-router-dom";
import { RootState } from '../../redux-store/types';
import { useSelector } from "react-redux";


export const Home: React.FC = () => {
  const cardList = [
    {
      logoName: "data-visualization-icon.svg",
      name: "Data Visualization",
      url: `https://explorer.heureka.quest/login`,
    },
    {
      logoName: "chat-bot-icon.svg",
      name: "Text-Based chat bot",
      url: "https://converse.heureka.quest/",
    },
    {
      logoName: "data-analytics-icon.svg",
      name: "Conversational Data analytics",
      url: "#",
    },
    {
      logoName: "map-interface-icon.svg",
      name: "Conversational Map interface",
      url: "#",
    },
    {
      logoName: "ai-analytics-icon.svg",
      name: "AI analytics pipelines",
      url: "#",
    },
  ];
  const navigate = useNavigate();
  const userInfo = useSelector((state: RootState) => state.userInfo);
  console.log(userInfo?.authToken)
  console.log("home page")
  useEffect(()=>{
    if(!userInfo?.authToken){
     navigate('/signin')
    }
   },[])
  const handleCard = (url: string) => {
    if(url!='#')
    window.open(url, "_blank");
  };
  return (
    <div className="home-main-section">
      <SEO
        title="Enacts | Home"
        description="Welcome to the Home page of my app."
      />
      <div className="home-main-container">
        <div className="home-info-section">
          <span>Hello,</span>
          <h3>How can we help you today?</h3>
        </div>
        <div className="home-cards-section">
          {cardList.map((data, index) => {
            return (
              <div
                className="card"
                onClick={() => handleCard(data.url)}
                key={index}
              >
                <img
                  src={getPublicAssetPath(`home/${data.logoName}`)}
                  alt="card-icon"
                />
                <h4>{data.name}</h4>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
