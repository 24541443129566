import { SignIn } from "../pages/SignIn/SignIn";
import { Home } from "../pages/Home/Home";
import { NotFound } from "../pages/NotFound/NotFound";
import { Landing } from "../pages/Landing/Landing";

interface Route {
    path: string;
    component: React.FC;
    isProtected?: boolean;
}

const routes: Route[] = [
    {
        path: '/',
        component: Landing,
    },
    {
        path: '/signin',
        component: SignIn,
    },
    {
        path: '/home',
        component: Home,
        isProtected: true,
    },
    {
        path: '*', // Catch-all route for 404
        component: NotFound,
    },
];

export default routes;
