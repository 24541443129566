import React, { useState } from "react";
import "./Header.css";
import { getPublicAssetPath } from "../../utils/getPublicAssets";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux"; 
// @ts-ignore
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { RootState } from '../../redux-store/types';

const Header: React.FC = () => {
  const userInfo = useSelector((state: RootState) => state.userInfo);
  const isAuthenticated  = userInfo?.authToken ? true :false
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isActiveLink = (linkPath: string, isOverlayNav: boolean) => {
    const normalizePath = (path: string) => path.replace(/\/+$/, "");
    return normalizePath(location.pathname) === normalizePath(linkPath)
      ? isOverlayNav
        ? "overlay-active-link"
        : "active-link"
      : "";
  };
  const logout= () =>{
    console.log("logging out...")
    Cookies.remove('enacts_auth_token', { path: '/', domain: 'heureka.quest' });
     dispatch({type:'LOG_OUT'})
     navigate('/signin')
     
  }

  return (
    <header className="header">
      <div className="nav-container">
        <div className="logo-container">
          <img
            src={getPublicAssetPath("header/head-1-logo.png")}
            alt="logo-1"
          />
          <img
            src={getPublicAssetPath("header/head-2-logo.png")}
            alt="logo-2"
          />
        </div>

        <nav>
          <ul>
            <li>
              <Link to="/home" className={isActiveLink("/home", false)}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className={isActiveLink("/about", false)}>
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/food-system"
                className={isActiveLink("/food-system", false)}
              >
                Food System
              </Link>
            </li>
            <li>
              <Link
                to="/convergence"
                className={isActiveLink("/convergence", false)}
              >
                Convergence
              </Link>
            </li>
            <li>
              <Link to="/contact" className={isActiveLink("/contact", false)}>
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="profile-container">
        {isAuthenticated ? (
              <button className="logout-btn"   onClick={logout} type="submit">
                     Logout
                    </button>
                
        ) : (
          <button
            className="header-signin-btn"
            onClick={() => (window.location.href = "/signin")}
          >
            Sign In
          </button>
        )}
      </div>

      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>

      {/* Full-screen overlay menu */}
      <div className={`overlay ${isMenuOpen ? "show" : ""}`}>
        <div className="profile-container-overlay">
          {isAuthenticated ? (
            <img
              src={getPublicAssetPath("header/profile-white.svg")}
              alt="profile-logo"
            />
          ) : (
            <button
              className="overlay-signin-btn"
              onClick={() => (window.location.href = "/signin")}
            >
              Sign In
            </button>
          )}
        </div>

        <nav>
          <ul>
            <li>
              <Link
                to="/home"
                className={isActiveLink("/home", true)}
                onClick={toggleMenu}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={isActiveLink("/about", true)}
                onClick={toggleMenu}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/food-system"
                className={isActiveLink("/food-system", true)}
                onClick={toggleMenu}
              >
                Food System
              </Link>
            </li>
            <li>
              <Link
                to="/convergence"
                className={isActiveLink("/convergence", true)}
                onClick={toggleMenu}
              >
                Convergence
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={isActiveLink("/contact", true)}
                onClick={toggleMenu}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
        <button className="close-btn" onClick={toggleMenu}>
          ✕
        </button>
      </div>
    </header>
  );
};

export default Header;
