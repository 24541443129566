import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../store/authStore";
import { RootState } from '../redux-store/types';
import { useSelector } from "react-redux";
interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const userInfo = useSelector((state: RootState) => state.userInfo);
  
  if (!userInfo?.authToken) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
