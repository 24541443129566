import React, { useEffect, useState } from "react";
import "./SignIn.css";
import { SEO } from "../../components/SEO";
import { useForm } from "react-hook-form";
import { getPublicAssetPath } from "../../utils/getPublicAssets";
import { useDispatch, useSelector } from "react-redux";
import { handleLoginUser } from "../../redux-store/actions/authAction";
import { Link, useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store/authStore";
import { RootState } from "../../redux-store/types";
import Cookies from "js-cookie";
import { useLocation,redirect } from "react-router-dom";

export const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectUrl = queryParams.get("redirect_url");
  console.log("query params", redirectUrl);
  const userInfo = useSelector(state => state.userInfo);
  useEffect(() => {
    if (userInfo?.authToken) {
      navigate("/");
    } else {
      setLoading(false); // Set loading to false once we know the auth status
    }
  }, [userInfo, navigate]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // @ts-ignore
  const onSubmit = (data) => {
    setLoading(true);
    const { username: phoneNumber, password: pin } = data;
    const user = {
      phoneNumber,
      password: pin,
    };
    dispatch(
      handleLoginUser(user, (response) => {
        if (response?.token) {
          console.log("cookie token", response?.token);
          Cookies.set("enacts_auth_token", response?.token, {
            domain: 'heureka.quest', 
            path: "/",
            secure: true,
            sameSite: "None",
          });
          setLoading(false);
          if (redirectUrl && redirectUrl == "data_visualization") {
            console.log("redirecting...");
            window.location.href = "https://explorer.heureka.quest/";
          } else {
            navigate("/");
          }
        } else {
          setLoading(false);
          console.log("Login failed. Please try again.");
        }
      })
    );
  };
  const redirectToSignup = () => {
    // Open the authentication window with specified dimensions
    const popup = window.open('https://authenticator.ulogged.in/', 'oauth', 'height=720,width=600');

    // Listen for messages from the popup window
    // @ts-ignore
    const handleMessage = (event) => {
      // Verify the message origin for security reasons
      if (event.origin !== 'http://54.91.139.129') {
        console.error('Origin mismatch:', event.origin);
        return;
      }

      // Receive and process the user data from the popup
      const userData = event.data;
      console.log('User Data:', userData);

      // Dispatch the login action with the received data

    // Attach event listener to receive messages from the popup
    window.addEventListener('message', handleMessage);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  };

}
const redirectToUpdatePassword = () =>{
  const popup = window.open('https://authenticator.ulogged.in/forgot-password', 'oauth', 'height=720,width=600');

  // Listen for messages from the popup window
  // @ts-ignore
  const handleMessage = (event) => {
    // Verify the message origin for security reasons
    if (event.origin !== 'http://54.91.139.129') {
      console.error('Origin mismatch:', event.origin);
      return;
    }

    // Receive and process the user data from the popup
    const userData = event.data;
    console.log('User Data:', userData);

    // Dispatch the login action with the received data

  // Attach event listener to receive messages from the popup
  window.addEventListener('message', handleMessage);

  // Clean up event listener on component unmount
  return () => {
    window.removeEventListener('message', handleMessage);
  };
};
}

  return (
    <div className="signin-main-section">
      <SEO
        title="Enacts | SignIn"
        description="Welcome to the SignIn page of my app."
      />
      <img src={getPublicAssetPath("signin/bg-vector.svg")} alt="bg-vector" />
      <div className="signin-container">
        <div className="signin-left-section">
          <h3>
            <span>Hello,</span> <br /> Be a part! Design your own part!
          </h3>
        </div>
        <div className="signin-right-section">
          <div className="form-container">
            <form className="" onSubmit={handleSubmit(onSubmit)}>
              <h3>SIGN IN</h3>
              <input
                type="text"
                placeholder="User Name"
                id="username"
                {...register("username", {
                  required: true,
                })}
                className={`${errors.username ? "error-para" : ""}`}
              />
              <input
                type="password"
                placeholder="Password"
                id="password"
                {...register("password", {
                  required: true,
                })}
                className={`${errors.password ? "error-para" : ""}`}
              />
              <button className="signin-btn" type="submit">
              {loading?'Loading...' : 'Sign In'}
            </button>
            </form>
            <p className="forgot-para" onClick={redirectToUpdatePassword}>Forgot Password ?</p>
            <Link to="https://authenticator.ulogged.in/" target="_blank">
              <button className="register-btn" onClick={redirectToSignup}>Register Now</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
